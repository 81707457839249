import {request} from "./request";

export function test() {
    return request({
        url:'/analysis/strength/month',
        method:'get'
    })

}
export function getYear(data) {
    return request({
        url: '/analysis/strength/month',
        method: 'post',
        data: JSON.stringify(data),
        headers:{

            'Content-Type':'application/json'
        }
    })
}
