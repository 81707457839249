<template>
    <div style="width: 100%;height: 100%;padding: 10px" class="d-flex flex-column">
        <v-card id="filter" style="padding: 10px 0">

                <span class="demonstration" style="margin: 0 17px">选择日期</span>
                <el-date-picker
                        v-model="data1"
                        type="date"
                        placeholder="选择日期">
                </el-date-picker>

                <span class="demonstration" style="margin: 0 10px">至</span>
                <el-date-picker
                        v-model="data2"
                        type="date"
                        placeholder="选择日期">
                </el-date-picker>

            <v-btn color="primary" style="margin-left: 10px;float: right;display: inline-block;margin-right: 40px"
                   @click="check()">
                <v-icon left>
                    mdi-magnify
                </v-icon>
                查看
            </v-btn>

        </v-card>

        <v-card class="flex-grow-1" style="background-color: #FFFFFF;margin-top: 12px;padding-top: 12px">
            <div id="distribution" style="height: 100%;"/>
        </v-card>
    </div>

</template>

<script>
    import * as chinaMap from "../network/details/china_map"
    import * as tp from "../network/details/timeperiod";

    export default {
        name: "Earthquake_Distribution",

        data: () => (
            {
                mapData: [],
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                value: '',

                data1: '',
                data2: '',

                charts: {
                    distribution: null,

                },

            }
        ),
        created() {
        },
        mounted() {
            //初始化自适应窗体大小
            window.onresize = () => {
                if (this.charts.distribution !== null) {
                    this.charts.distribution.resize();
                }
            }
            //初始化图表
            setTimeout(() => {
                    this.echartsInit()
                }
            )
        }
        ,
        methods: {
            setOption(data) {
                const data_value_list = [5000, 1225, 833, 65, 0,]
                return {
                    title: {
                        text: '地震分布',
                        // subtext: '数据源：前程无忧',
                        x: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '省份：{b}<br/>数值：{c}'
                    },

                    dataRange: {
                        min: 0,
                        max: Math.max.apply(null, data_value_list),
                        x: 'left',
                        y: 'bottom',
                        text: ['高', '低'],           // 文本，默认为数值文本
                        calculable: true,
                        inRange: {
                            color: ['#FFFFFF', '#0000FF'],
                            symbolSize: [10, 100]
                        }
                    },
                    series: [
                        {
                            name: '数据',
                            type: 'map',
                            mapType: 'china',
                            roam: false,
                            itemStyle: {
                                normal: {label: {show: true}},
                                emphasis: {label: {show: true}}
                            },
                            data: data
                        }
                    ]
                }
            },
            echartsInit() {
                //获取渲染图表的容器
                let distribution = document.getElementById('distribution');

                //对容器内容赋值
                if (this.charts.distribution === null) {
                    this.charts.distribution = this.$echarts.init(distribution);
                }

                //自适应
                window.addEventListener('resize', () => {
                    if (this.charts.distribution) {
                        this.charts.distribution.resize();
                    }
                });
                //设置图表属性
                this.setDistribution()
            },
            setDistribution() {
                chinaMap.getmap().then(res => {
                    let data_list = res.data
                    this.charts.distribution.setOption(this.setOption(data_list))

                })
            },
            check() {
                let yy = new Date(this.data1).getFullYear();
                let mm = new Date(this.data1).getMonth()+1;
                let dd = new Date(this.data1).getDate();
                let data1=yy+"-"+mm+"-"+dd
                yy = new Date(this.data2).getFullYear();
                mm = new Date(this.data2).getMonth()+1;
                dd = new Date(this.data2).getDate();
                let data2=yy+"-"+mm+"-"+dd

                chinaMap.getmonth({
                    date_from:data1,
                    date_to:data2
                }).then(res => {
                    console.log(res);
                    let data_list = res.data
                    this.charts.distribution.setOption(this.setOption(data_list))
                })
            }
        }
        ,
    }
</script>

<style scoped>

</style>
