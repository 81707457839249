import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Root from '../views/Root.vue'
import Earthquake_Distribution from "../views/Earthquake_Distribution";
import Earthquake_Statistics from "../views/Earthquake_Statistics";
import Earthquake_Times from "../views/Earthquake_Times";
import Earthquake_Details from "../views/Earthquake_Details";
import Earthquake_Timeperiod from "../views/Earthquake_Timeperiod";
import BigScreen from "../views/Big_Screen";

Vue.use(VueRouter)
const commonRoutes = [
    {
        path: '/',
        redirect: '/home',
        meta: {
            title: '在线考试系统'
        }
    },
]
const routes = [
    {
        path: '/root',
        component: Root,
        meta: {
            title: '地震可视化分析系统'
        },
        children: [
            {
                path: '/home',
                component: Home,
                meta: {
                    icon: 'mdi-home-outline',
                    title: '首页'
                },
            },
            {
                path: '/distribute',
                component: Earthquake_Distribution,
                meta: {
                    icon: 'mdi-map-search-outline',
                    title: '地震分布'
                },
            },{
                path: '/statistic',
                component: Earthquake_Statistics,
                meta: {
                    icon: 'mdi-chart-arc',
                    title: '地震统计'
                },
            },{
                path: '/times',
                component: Earthquake_Times,
                meta: {
                    icon: 'mdi-chart-bar',
                    title: '地震频次'
                },
            },{
                path: '/timePeriod',
                component: Earthquake_Timeperiod,
                meta: {
                    icon: 'mdi-timer-sand',
                    title: '地震时间段统计'
                },
            },{
                path: '/details',
                component: Earthquake_Details,
                meta: {
                    icon: 'mdi-post-outline',
                    title: '地震详情'
                },
            },
        ]
    },

    {
        path: '/bigScreen',
        component: BigScreen,
        meta: {
            icon: 'mdi-home-outline',
            title: '大屏展示'
        },
    },
    ...commonRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    console.log(to)
    window.document.title = to.meta.title;
    next();
})
export default router
