import {request} from "../request";

export function getmap() {
    return request({
        url: '/analysis/strength/map/china/cnt',
        method: 'get',
    })
}
export function getmonth(data) {
    return request({
        url: '/analysis/strength/map/china/cnt',
        method: 'get',
        params:data
    })
}

