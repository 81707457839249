<template>
    <div style="width: 100%;height: 100%;padding: 10px" class="d-flex flex-column">
        <v-card id="filter" style="padding: 10px 0">
            <div class="block" style="display: inline-block;margin-left: 17px">
                <div class="block">
                    <span class="demonstration" style="margin-right: 10px">选择年份</span>
                    <el-date-picker
                            v-model="yearValue"
                            type="year"
                            placeholder="选择年">
                    </el-date-picker>
                </div>
            </div>
            <div class="sel" style="display: inline-block;margin-left: 17px;">
                <span class="demonstration" style="margin-right: 10px">地点</span>
                <el-select v-model="value" filterable multiple placeholder="请选择">
                    <el-option
                            v-for="item in options"
                            :label="item"
                            :value="item">
                    </el-option>
                </el-select>
            </div>
            <v-btn color="primary" style="margin-left: 10px;float: right;display: inline-block;margin-right: 40px"
                   @click="checktp()">
                <v-icon left>
                    mdi-magnify
                </v-icon>
                查看
            </v-btn>

        </v-card>

        <v-card class="flex-grow-1" style="background-color: #FFFFFF;margin-top: 12px;padding-top: 12px">
            <div style="height: 100%;" id="charsTop"></div>
        </v-card>
    </div>

</template>

<script>
    import * as echars from "echarts"
    import * as tp from "../network/details/timeperiod"
    import * as chinaMap from "../network/details/china_map";

    export default {
        name: "Earthquake_Timeperiod",
        data: () => (
            {
                level: {
                    lv1: [],
                    lv2: [],
                    lv3: [],
                    lv4: [],
                    lv5: [],
                },
                labels: [],
                options: ['河北', '山西', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东',
                    '海南', '四川', '贵州', '云南', '陕西', '甘肃', '青海', '台湾', '内蒙古', '广西', '西藏', '宁夏', '新疆', '北京', '天津', '上海',
                    '重庆', '香港', '澳门']
                ,
                value: '',

                yearValue: '',

                charts: {
                    timeperiod: null,

                },

            }
        ),
        mounted() {
            window.onresize = () => {
                if (this.charts.timeperiod !== null) {
                    this.charts.timeperiod.resize();
                }
            }
            //初始化图表
            setTimeout(() => {
                    this.echartsInit()
                }
            )
        },
        methods: {
            echartsInit() {
                //获取渲染图表的容器
                let timeperiod = document.getElementById('charsTop');

                //对容器内容赋值
                if (this.charts.timeperiod === null) {
                    this.charts.timeperiod = this.$echarts.init(timeperiod);
                }

                //自适应
                window.addEventListener('resize', () => {
                    if (this.charts.timeperiod) {
                        this.charts.timeperiod.resize();
                    }
                });
                //设置图表属性
                this.setTimeperiod()
            },
            setTimeperiod() {
                tp.getTime().then(res => {
                    this.labels = res.data.labels
                    this.level.lv1 = res.data.lv1
                    this.level.lv2 = res.data.lv2
                    this.level.lv3 = res.data.lv3
                    this.level.lv4 = res.data.lv4
                    this.level.lv5 = res.data.lv5
                    this.charts.timeperiod.setOption(this.setOption())

                })
            },
            checktp() {
                let yy = new Date(this.yearValue).getFullYear();
                tp.getYear({
                    year: yy,
                    provinces: this.value
                }).then(res => {
                    console.log("1111111111")
                    console.log(res);
                    this.labels = res.data.labels
                    this.level.lv1 = res.data.lv1
                    this.level.lv2 = res.data.lv2
                    this.level.lv3 = res.data.lv3
                    this.level.lv4 = res.data.lv4
                    this.level.lv5 = res.data.lv5
                    this.charts.timeperiod.setOption(this.setOption())
                })
            },
            setOption() {
                return {
                    title: {
                        text: '地震时间统计图'
                    },
                    tooltip: {
                        trigger: 'axis',//触发类型
                        axisPointer: {//显示方式
                            type: 'shadow'
                        }
                    },
                    legend: {},//图例显示与否
                    grid: {//容器和内容的距离
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top: '5%',
                        containLabel: true
                    },

                    xAxis: [
                        {
                            type: 'category',
                            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            position: 'left',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                            },
                            axisLabel: {
                                formatter: '{value} 次'
                            }
                        }

                    ],
                    series: [
                        {
                            name: '有感地震[3-4.5)',
                            type: 'bar',
                            stack: 'Ad',
                            emphasis: {
                                focus: 'series'
                            },
                            markPoint: {
                                data: [
                                    {
                                        type: "max",
                                        name: '最大值'
                                    }, {
                                        type: "min",
                                        name: "最小值"
                                    }
                                ]
                            },
                            markLine: {
                                data: [
                                    {
                                        type: "average",
                                        name: "平均值"
                                    }
                                ]
                            },
// 需要获取的数据：每个月的地震次数
                            data: this.level.lv1
                        },
                        {
                            name: '中强震[4.5-6)',
                            type: 'bar',
                            stack: 'Ad',
                            emphasis: {
                                focus: 'series'
                            },
                            markPoint: {
                                data: [
                                    {
                                        type: "max",
                                        name: '最大值'
                                    }, {
                                        type: "min",
                                        name: "最小值"
                                    }
                                ]
                            },
                            markLine: {
                                data: [
                                    {
                                        type: "average",
                                        name: "平均值"
                                    }
                                ]
                            },
// 需要获取的数据：每个月的地震次数
                            data: this.level.lv2
                        },
                        {
                            name: '强震[6-7)',
                            type: 'bar',
                            stack: 'Ad',
                            emphasis: {
                                focus: 'series'
                            },
                            markPoint: {
                                data: [
                                    {
                                        type: "max",
                                        name: '最大值'
                                    }, {
                                        type: "min",
                                        name: "最小值"
                                    }
                                ]
                            },
                            markLine: {
                                data: [
                                    {
                                        type: "average",
                                        name: "平均值"
                                    }
                                ]
                            },
// 需要获取的数据：每个月的地震次数
                            data: this.level.lv3
                        },
                        {
                            name: '大地震[7-8)',
                            type: 'bar',
                            stack: 'Ad',
                            emphasis: {
                                focus: 'series'
                            },
                            markPoint: {
                                data: [
                                    {
                                        type: "max",
                                        name: '最大值'
                                    }, {
                                        type: "min",
                                        name: "最小值"
                                    }
                                ]
                            },
                            markLine: {
                                data: [
                                    {
                                        type: "average",
                                        name: "平均值"
                                    }
                                ]
                            },
// 需要获取的数据：每个月的地震次数
                            data: this.level.lv4
                        },
                        {
                            name: '巨大地震[8,∞)',
                            type: 'bar',
                            stack: 'Ad',
                            emphasis: {
                                focus: 'series'
                            },
                            markPoint: {
                                data: [
                                    {
                                        type: "max",
                                        name: '最大值'
                                    }, {
                                        type: "min",
                                        name: "最小值"
                                    }
                                ]
                            },
                            markLine: {
                                data: [
                                    {
                                        type: "average",
                                        name: "平均值"
                                    }
                                ]
                            },
// 需要获取的数据：每个月的地震次数
                            data: this.level.lv5
                        },
                        window.addEventListener("resize", () => {
                            statistic_chart.resize()
                        })

                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>
