<template>

  <div style="width: 100%;height: 100%;padding: 10px;" class="d-flex flex-column">
    <v-card id="filter" style="padding: 10px 0">
      <div class="block" style="display: inline-block;margin-left: 17px">
        <div class="block">
          <span class="demonstration" style="margin-right: 10px">选择年份</span>
          <el-date-picker
                  v-model="yearValue"
                  type="year"
                  placeholder="选择年">
          </el-date-picker>
        </div>
      </div>

      <v-btn color="primary" style="margin-left: 10px;float: right;display: inline-block;margin-right: 40px" @click="chooseYear" >
        <v-icon left>
          mdi-magnify
        </v-icon>
        查看
      </v-btn>

    </v-card>
    <div style="height: 100%;" class="d-flex justify-space-around">
      <v-card style="margin: 10px 10px 10px 0px;width: 50%;padding-top: 15px" id="times_chart">

      </v-card>
      <v-card style="margin: 10px 10px 10px 0px;width: 50%;padding-top: 15px;" id="times_charts">

      </v-card>
    </div>

  </div>
</template>

<script>
import * as echars from "echarts"
import * as query from "../network/Times_analysis"
export default {
name: "Earthquake_Times",
    data: () => (
        {
            yearValue:'',
            mapData: [],

            selectMonth: '',

            charts: {
                distribution: null,

            },

        }
    ),
    methods:{
    setoption02(prince_data){
        return{
            title: {
                text: '地震年度频次前六省份',
                // subtext: 'Fake Data',
                left: 'center'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },

            tooltip: {
                trigger: 'item'
            },
            series: [
                {
                    avoidLabelOverlap: false,
                    name: '省份 和 频次',
                    type: 'pie',
                    radius: [70, 250],
                    center: ['50%', '50%'],
                    roseType: 'area',
                    itemStyle: {
                        borderRadius: 8,
                        label:{
                            show:true,
                            position:"center"
                        }
                    },
                    label:{
                        show:true,
                        position:"inside"
                    },
                    data: prince_data,


                    emphasis: {
                        label: {
                            formatter: '{b}地震，\n 共{c}次',
                            show: true,
                            position:"center",
                            fontSize: '20',
                            fontWeight: 'bold'
                        }
                    },
                },
                window.addEventListener("resize", () => {
                    times_charts.resize()
                })
            ]
        }
    },
    setoption(month_data){
        return{
            title: {
                text: '地震年度频次',
                // subtext: 'Fake Data',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },


            series: [
                {

                    name: '震级 和 频次',
                    type: 'pie',
                    data: month_data,
                    radius: ['20%', '60%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                        label:{
                            show:true,
                            position:"inside"
                        }
                    },
                    label:{
                        show:true,
                        position:"inside"
                    },
                    emphasis: {
                        label: {
                            formatter: '震级：{b}，\n 共{c}次',
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold'
                        }
                    },


                },
                window.addEventListener("resize", () => {
                    times_chart.resize()
                })

            ]
        }

    },
    chooseYear(){
        let yyy = new Date(this.yearValue).getFullYear();
        query.getProvinceYear({
            date_from:yyy+"-1-1",
            date_to:yyy+"-12-31",
        }).then(res=>{
            for(let i=0;i<33;i++){
                for(let j=0;j<32;j++){
                    if (res.data[j+1]["value"]>=res.data[j]["value"]) {
                        let a=res.data[j+1]
                        res.data[j+1]=res.data[j]
                        res.data[j]=a
                    }
                }
            }
            let prince_data=[
                { value: 40, name: 'rose 1' },
                { value: 38, name: 'rose 2' },
                { value: 32, name: 'rose 3' },
                { value: 30, name: 'rose 4' },
                { value: 28, name: 'rose 5' },
                { value: 26, name: 'rose 6' },

            ];
            for(let i=0;i<6;i++){
                prince_data[i]["value"]=res.data[i]["value"]
                prince_data[i]["name"]=res.data[i]["name"]
            }
            console.log("88888")
            console.log(prince_data)
            let times_charts=echars.init(document.getElementById("times_charts"));
            times_charts.setOption(this.setoption02(prince_data))
        })
        let yy = new Date(this.yearValue).getFullYear();
        query.getYear({
            year: yy,
            provinces: this.value
        }).then(res => {
            console.log("121212")
            console.log(res);
            let month_data=[
                // 需要获取的数据
                { value: res.data['lv1'], name: '有感地震[3-4.5)' },
                { value: res.data['lv2'], name: '中强震[4.5-6)' },
                { value: res.data['lv3'], name: '强震[6-7)' },
                { value: res.data['lv4'], name: '大地震[7-8)' },
                { value: res.data['lv5'], name: '巨大地震[8,∞)' },

            ]
            console.log(month_data)
            let times_chart=echars.init(document.getElementById("times_chart"));
            times_chart.setOption(this.setoption(month_data));

        })
    }
    },
    mounted(){
    let times_chart=echars.init(document.getElementById("times_chart"));
    query.getAll().then(res=>{
        let month_data=[
            // 需要获取的数据
            { value: res.data['lv1'], name: '有感地震[3-4.5)' },
            { value: res.data['lv2'], name: '中强震[4.5-6)' },
            { value: res.data['lv3'], name: '强震[6-7)' },
            { value: res.data['lv4'], name: '大地震[7-8)' },
            { value: res.data['lv5'], name: '巨大地震[8,∞)' },

        ]
        times_chart.setOption(this.setoption(month_data));
    })





        let times_charts=echars.init(document.getElementById("times_charts"));
        query.getProvince().then(res=>{
            // let i=0;
            for(let i=0;i<33;i++){
                for(let j=0;j<32;j++){
                    if (res.data[j+1]["value"]>=res.data[j]["value"]) {
                        let a=res.data[j+1]
                        res.data[j+1]=res.data[j]
                        res.data[j]=a
                    }
                }
            }
            console.log(res.data)
            let prince_data=[
                { value: 40, name: 'rose 1' },
                { value: 38, name: 'rose 2' },
                { value: 32, name: 'rose 3' },
                { value: 30, name: 'rose 4' },
                { value: 28, name: 'rose 5' },
                { value: 26, name: 'rose 6' },

            ];
            for(let i=0;i<6;i++){
                prince_data[i]["value"]=res.data[i]["value"]
                prince_data[i]["name"]=res.data[i]["name"]
            }
            console.log(prince_data)
            times_charts.setOption(this.setoption02(prince_data))
        });


    },

}
</script>

<style scoped>

</style>
