<template>
    <div style="padding: 10px;height: 100%;width: 100%">
        <v-card elevation="1" class="d-flex flex-column flex-grow-1" style="padding: 10px;height: 100%">
            <div class="flex-grow-1">
                <el-input style="width: 200px;" clearable v-model="filter.search"
                          placeholder="请输入内容" prefix-icon="el-icon-search">
                </el-input>
                <v-btn color="primary" style="margin-left: 10px" @click="search" @keypress.enter.native="search">
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    搜索
                </v-btn>
                <div style="float: right">
                    <v-btn color="primary" @click="initQuakeData" style="margin-right: 10px">
                        <v-icon left>
                            mdi-refresh
                        </v-icon>
                        刷新
                    </v-btn>
                </div>

                <el-table highlight-current-row style="width: 100%"
                          tooltip-effect="dark" :data="tableData">
                    <el-table-column label="id" width="100" align="center" prop="id"/>
                    <el-table-column label="震级" width="100" align="center" prop="magnitude"/>
                    <el-table-column label="级别" width="100" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="parseFloat(scope.row.magnitude)<3" type="info">
                                弱震
                            </el-tag>
                            <el-tag
                                v-else-if="parseFloat(scope.row.magnitude)>=3 && parseFloat(scope.row.magnitude)<4.5"
                                type="">
                                有感地震
                            </el-tag>
                            <el-tag
                                v-else-if="parseFloat(scope.row.magnitude)>=4.5 && parseFloat(scope.row.magnitude)<6"
                                type="success">
                                中强震
                            </el-tag>
                            <el-tag v-else-if="parseFloat(scope.row.magnitude)>=6 && parseFloat(scope.row.magnitude)<7"
                                    type="info">
                                强震
                            </el-tag>
                            <el-tag v-else-if="parseFloat(scope.row.magnitude)>=7 && parseFloat(scope.row.magnitude)<8"
                                    type="warning">
                                大地震
                            </el-tag>
                            <el-tag v-else type="danger">
                                巨大地震
                            </el-tag>

                        </template>
                    </el-table-column>
                    <el-table-column label="经度" width="100" align="center" prop="longitude"/>
                    <el-table-column label="纬度" width="100" align="center" prop="latitude"/>
                    <el-table-column label="深度(km)" width="100" align="center" prop="depth"/>
                    <el-table-column label="省份" width="100" align="center" prop="province">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.province==='海外'" type="success">
                                {{ scope.row.province }}
                            </el-tag>
                            <span v-else>
                                {{ scope.row.province }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="发生时刻(UTC + 8)" width="200" align="center" prop="time"/>
                    <el-table-column label="参考位置" show-overflow-tooltip align="center" prop="location"/>
                </el-table>
            </div>
            <el-pagination
                @size-change="pageSizeChange"
                @current-change="pageCurrentChange"
                style="margin-top: 15px;margin-left: 15px;min-height: 32px"
                background hide-on-single-page
                :page-sizes="page.sizeFilter"
                :page-size="page.size"
                :current-page.sync="page.current"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total">
            </el-pagination>
        </v-card>
    </div>
</template>

<script>
    import * as util from "../assets/js/util";
    import * as query from '../network/details/query'

    export default {
        name: "Earthquake_Details",
        data: () => ({
            allTableData: [],
            tableData: [],
            page: {
                current: 1,
                size: 10,
                sizeFilter: [10, 20, 50, 100],
                total: 0
            },
            filter: {
                search: "",
                allTableData: []
            }
        }),
        watch: {
            "filter.search"(newVal) {
                if (newVal === "") {
                    this.pageCurrentChange(1)
                }
            }
        },
        created() {
            this.initQuakeData()
        },
        methods: {
            search() {
                this.filter.allTableData = this.allTableData.filter(val => {
                    let values = Object.values(val)
                    for (let i = 0; i < values.length; i++) {
                        let v = values[i]
                        let search = this.filter.search.toLowerCase()
                        if (v && v.toString().toLowerCase().includes(search)) {
                            return true;
                        }
                    }
                    return false;
                })
                this.pageCurrentChange(1)
            },
            pageSizeChange(size) {
                this.page.size = size;
                this.pageCurrentChange(1)
            },
            pageCurrentChange(page) {
                this.page.current=page
                let start = this.page.size * (page - 1);
                let end = this.page.size * page;
                let allTableData = this.filter.search !== '' ? this.filter.allTableData : this.allTableData
                this.tableData = allTableData.slice(start, end);
                if (this.filter.search){
                    this.page.total = this.filter.allTableData.length
                }else{
                    this.page.total = this.allTableData.length
                }
            },
            initQuakeData() {
                query.getAll().then(res => {
                    this.allTableData = res.data;
                    this.pageCurrentChange(1)
                })
            }
        }
    }
</script>

<style scoped>

</style>
