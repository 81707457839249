<template>
    <v-app id="inspire">
        <v-navigation-drawer v-model="drawer" app>
            <div style="height: 64px" class="nav-title d-flex justify-center">
                <img src="../assets/img/logo.png" alt="" width="44" height="44">
                <span>地震可视化分析系统</span>
            </div>
            <v-divider/>
            <v-list nav shaped>
                <v-list-item-group mandatory v-model=menu_selected>
                    <template v-for="(item,i) in this.menu" >
                        <v-list-item color="primary" @click="gotoPage(item,item.path)" link
                                     v-if="item.children===undefined||item.children.length===0">
                            <v-list-item-icon>
                                <v-icon>{{ item.meta.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app style="background: white">

            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item style="font-size: 17px " v-for="(item,i) in breadcrumb"
                                        :key="i" :to="{path:item.path}">
                        {{ item.meta.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </v-toolbar-title>
            <v-spacer/>
        </v-app-bar>

        <v-main style="width: 100%;height: 100%;background: #F6F7F9;">
            <keep-alive>
                <router-view style="width:100%;height:100%;padding: 10px;overflow:auto hidden"></router-view>
            </keep-alive>
        </v-main>
    </v-app>
</template>

<script>
    import * as util from '../assets/js/util'

    export default {
        name: "Root",
        data: () => ({
            //抽屉相关
            current_selected: undefined,
            current_children_selected: undefined,
            menu_selected: 1,
            menu_children_selected: undefined,
            //抽屉
            drawer: null,
            //消息条提示
            snackbar: {
                color: '',
                text: '',
                time: '',
                show: false
            },
            //菜单
            menu: [],
            // 面包屑
            breadcrumb: []
        }),
        computed: {},
        watch: {
            /**
             * 监听路由变化，路由变化后更新面包屑和侧边菜单栏
             * */
            $route() {
                this.initBreadcrumb();
                this.initMenu()
            },
        },
        created() {
            this.initBreadcrumb();
            this.initMenu()
        },
        methods: {
            /**
             * 初始化面包屑
             * */
            initBreadcrumb() {
                this.breadcrumb = this.$route.matched.filter((item) => {
                    return item.meta;
                })
                //面包屑第一个的地址
                this.breadcrumb[0].path = "/home";
            },
            /**
             * 初始化菜单
             * */
            initMenu() {
                this.menu = this.$router.options.routes[0].children;
                for (let i = 0; i < this.menu.length; i++) {
                    //让菜单栏自动选择与当前路由相同的项
                    if (this.menu[i].path === this.$route.path) {
                        this.menu_selected = i;
                    }
                }
            },
            /**
             * 点击菜单页面跳转
             * @param menu 点击的菜单
             * @param path 菜单中对应的路径
             * */
            gotoPage(menu, path) {
                if (this.$route.path === path)
                    return
                this.$router.push({path: path})
            },
        },
    }
</script>

<style scoped lang="less">

    .avatar {
        user-select: none;
        margin-right: 10px;
        cursor: pointer;
    }

    .nav-title {
        user-select: none;

        img {
            position: relative;
            top: 50%;
            transform: translate(0, -50%);
        }

        span {
            display: inline-block;
            font-size: 17px;
            font-weight: bold;
            line-height: 64px;
            margin-left: 10px;
        }
    }
</style>
