<template>
    <div style="width: 100%;height: 100%;padding: 10px" class="d-flex flex-column">
        <v-card id="filter" style="padding: 10px 0">
            <div class="block" style="display: inline-block;margin-left: 17px">
                <div class="block">
                    <span class="demonstration" style="margin-right: 10px">选择年份</span>
                    <el-date-picker
                            v-model="yearValue"
                            type="year"
                            placeholder="选择年">
                    </el-date-picker>
                </div>
            </div>

            <div class="sel" style="display: inline-block;margin-left: 17px;">
                <span class="demonstration" style="margin-right: 10px">地点</span>
                <el-select v-model="value" filterable multiple placeholder="请选择">
                    <el-option
                            v-for="item in options"
                            :label="item"
                            :value="item">
                    </el-option>
                </el-select>
            </div>
            <v-btn color="primary" style="margin-left: 10px;float: right;display: inline-block;margin-right: 40px"
                   @click="chooseYear">
                <v-icon left>
                    mdi-magnify
                </v-icon>
                查看
            </v-btn>

        </v-card>

        <v-card class="flex-grow-1" style="background-color: #FFFFFF;margin-top: 12px;padding-top: 12px">
            <div id="statistic_chart" style="height: 100%;"/>
        </v-card>
    </div>
</template>

<script>
    import * as echarts from "echarts"
    import * as query from "../network/Statistics_analysis"

    export default {
        name: "Earthquake_Statistics",
        data: () => (
            {
                yearValue:'',
                mapData: [],
                firtLeve: [],
                options: ['河北', '山西', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东',
                    '海南', '四川', '贵州', '云南', '陕西', '甘肃', '青海', '台湾', '内蒙古', '广西', '西藏', '宁夏', '新疆', '北京', '天津', '上海',
                    '重庆', '香港', '澳门']
                ,
                value: '',

                startMonth: '',
                endMonth: '',



            }
        ),
        created() {
        },
        methods: {
            chooseYear(){
                let yy = new Date(this.yearValue).getFullYear();
                query.getYear({
                    year: yy,
                    provinces: this.value
                }).then(res => {
                    console.log("1111111111")
                    console.log(res);
                    let lowLine = res.data['lv1'];
                    let midLine=res.data['lv2'];
                    let hightLine=res.data['lv3'];
                    let hhightLine=res.data['lv4'];
                    let maxLine=res.data['lv5'];
                    let statistic_chart = echarts.init(document.getElementById("statistic_chart"))
                    statistic_chart.setOption(this.setoption(lowLine, midLine,hightLine,hhightLine,maxLine))
                })
            },
            setoption(lowLine, midLine,hightLine,hhightLine,maxLine){
                return{
                    title: {
                        text: '地震统计'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['有感地震[3-4.5)', '中强震[4.5-6)', '强震[6-7)','大地震[7-8)','巨大地震[8,∞)'],
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月',],
                    },
                    yAxis: {
                        type: 'value',
                        position: 'left',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: '{value} 次'
                        }
                    },
                    series: [
                        {
                            name: '有感地震[3-4.5)',
                            type: 'line',
                            stack: 'Total',
                            // smooth:true,
                            areaStyle: {},
                            markPoint: {
                                data: [
                                    {
                                        type: "max", name: "最大值"
                                    }, {
                                        type: "min", name: "最小值"
                                    }
                                ]
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            // 需要获取的数据：每个月的地震次数
                            data: lowLine
                        },
                        {
                            name: '中强震[4.5-6)',
                            type: 'line',
                            stack: 'Total',
                            // smooth:true,
                            areaStyle: {},
                            // 需要获取的数据：每个月的地震次数
                            markPoint: {
                                data: [
                                    {
                                        type: "max", name: "最大值"
                                    }, {
                                        type: "min", name: "最小值"
                                    }
                                ]
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: midLine
                        },
                        {
                            name: '强震[6-7)',
                            type: 'line',
                            stack: 'Total',
                            // smooth:true,
                            areaStyle: {},
                            // 需要获取的数据：每个月的地震次数
                            markPoint: {
                                data: [
                                    {
                                        type: "max", name: "最大值"
                                    }, {
                                        type: "min", name: "最小值"
                                    }
                                ]
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: hightLine
                        },
                        {
                            name:'大地震[7-8)',
                            type: 'line',
                            stack: 'Total',
                            // smooth:true,
                            areaStyle: {},
                            // 需要获取的数据：每个月的地震次数
                            markPoint: {
                                data: [
                                    {
                                        type: "max", name: "最大值"
                                    }, {
                                        type: "min", name: "最小值"
                                    }
                                ]
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: hhightLine
                        },
                        {
                            name: '巨大地震[8,∞)',
                            type: 'line',
                            stack: 'Total',
                            // smooth:true,
                            areaStyle: {},
                            // 需要获取的数据：每个月的地震次数
                            markPoint: {
                                data: [
                                    {
                                        type: "max", name: "最大值"
                                    }, {
                                        type: "min", name: "最小值"
                                    }
                                ]
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: maxLine
                        },
                        window.addEventListener("resize", () => {
                            statistic_chart.resize()
                        })
                    ],
                }
            },
        },
        mounted() {

            let lowLine = [];
            let midLine = [];
            let hightLine = [];
            let hhightLine=[];
            let maxLine=[];
            query.test().then(res => {
                lowLine = res.data['lv1'];
                midLine=res.data['lv2'];
                hightLine=res.data['lv3'];
                hhightLine=res.data['lv4'];
                maxLine=res.data['lv5'];
                let statistic_chart = echarts.init(document.getElementById("statistic_chart"))
                statistic_chart.setOption(this.setoption(lowLine, midLine,hightLine,hhightLine,maxLine))
            });


        }
    }
</script>

<style scoped>

</style>
