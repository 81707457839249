import {request} from "../request";

export function getAll() {
    return request({
        url: '/query/all',
        method: 'get',
    })
}

export function getAllByOffset(data, anim) {
    return request({
        url: '/query/all/offset',
        method: 'get',
        params: data,
        anim: anim !== false
    })
}
