import {request} from "../request";
import qs from "qs";

export function getTime() {
    return request({
        url: '/analysis/strength/month',
        method: 'get',
    })
}
export function getYear(data) {
    return request({
        url: '/analysis/strength/month',
        method: 'post',
        data: JSON.stringify(data),
        headers:{
            'Content-Type':'application/json'
        }
    })
}

