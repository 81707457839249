<template>
    <v-app>

        <v-snackbar v-model="snackbar.show" :timeout="snackbar.time" top right :color="snackbar.color">
            {{ snackbar.text }}
        </v-snackbar>
        <v-dialog scrollable v-model="dialog.show" :persistent="dialog.persistent" :max-width="dialog.maxWidth">
            <v-card>
                <v-card-title class="text-h5">
                    {{ dialog.title }}
                </v-card-title>
                <v-card-text>
                    <pre style="white-space: pre-wrap" class="json-high-line" v-if="dialog.pre"
                         v-html="dialog.message"/>
                    <div v-else v-html="dialog.message"></div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="dialog.disAgreeBtnColor" v-show="dialog.disAgreeShow" text
                           @click="dialog.disAgreeAction">
                        {{ dialog.disAgreeText }}
                    </v-btn>
                    <v-btn :color="dialog.agreeBtnColor" text v-show="dialog.agreeShow"
                           @click="dialog.agreeAction">
                        {{ dialog.agreeText }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <router-view/>
    </v-app>
</template>

<script>

    import * as util from "./assets/js/util";

    export default {
        name: 'app',
        data: () => ({
            //消息条提示
            snackbar: {
                color: '',
                text: '',
                time: '',
                show: false
            },
            //消息弹窗
            show: false,
            dialog: {
                pre: false,
                show: false,
                persistent: false,
                maxWidth: 290,
                title: "",
                message: "",
                disAgreeText: "取消",
                disAgreeShow: true,
                disAgreeAction: null,
                disAgreeBtnColor: "green darken-1",
                agreeText: "确定",
                agreeShow: true,
                agreeBtnColor: "green darken-1",
                agreeAction: null
            }
        }),
        watch: {
            "$store.state.msgDialog"(newValue) {
                this.showMsgDialog(newValue)
            }
        },
        created() {
            window.addEventListener("setItemEvent", e => {
                if (e.key === "SnackBar") {
                    this.showSnackBar(e)
                }
            });
            sessionStorage.setItem("serverError", "false")
        },
        methods: {
            showSnackBar(e) {
                console.log("showSnackBar")
                let msg = JSON.parse(e.newValue)
                this.snackbar.text = msg.text
                this.snackbar.color = msg.color ?? "primary"
                this.snackbar.time = msg.time ?? 3000
                this.snackbar.show = true
            },
            showMsgDialog(e) {
                let close = () => {
                    this.dialog.show = false;
                }
                console.log("showMsgDialog")
                let agreeAction = e.agreeAction;
                let disAgreeAction = e.disAgreeAction;
                e.agreeAction = () => {
                    agreeAction && agreeAction()
                    close();
                }
                e.disAgreeAction = () => {
                    disAgreeAction && disAgreeAction()
                    close();
                }
                this.dialog = e
            }
        }
    }
</script>

<style>
    .json-high-line {
        outline: 1px solid #ccc;
        padding: 5px;
        margin: 5px;
    }

    .string {
        color: green;
    }

    .number {
        color: darkorange;
    }

    .boolean {
        color: blue;
    }

    .null {
        color: magenta;
    }

    .key {
        color: red;
    }
</style>
