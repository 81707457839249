import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/element.js'
import * as global from './assets/js/util'
import * as echarts from 'echarts'
import dataV from '@jiaminghi/data-view'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
global.addLocalStorageSetEvent()

Vue.use(dataV)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
