import {request} from "./request";

export function getAll() {
    return request({
        url: '/analysis/strength/cnt',
        method: 'get'
    })
}
export function getYear(data) {
    return request({
        url: '/analysis/strength/cnt',
        method: 'post',
        data: JSON.stringify(data),
        headers:{
            'Content-Type':'application/json'
        }
    })
}

export function getProvince() {
    return request({
        url: '/analysis/strength/map/china/cnt',
        method: 'get',
    })
}
export function getProvinceYear(data) {
    return request({
        url: '/analysis/strength/map/china/cnt',
        method: 'post',
        data: JSON.stringify(data),
        headers:{

            'Content-Type':'application/json'
        }
    })
}
