import {request} from "../request";

export function getBigScreen() {
    return request({
        url: '/analysis/bigScreen',
        method: 'get',
        anim:false
    })
}

