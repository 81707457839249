<template>

    <dv-full-screen-container class="d-container bg d-flex flex-column">
        <div class="header d-flex justify-space-between">
            <dv-decoration-8 style="width:25%;height:50px;"/>
            <div style="width: 40%;">
                <p class="page-title">地震可视化分析系统</p>
                <dv-decoration-5 style="width:100%;margin-top:35px;height:40px;"/>
            </div>
            <dv-decoration-8 :reverse="true" style="width:25%;height:50px;"/>
        </div>
        <div class="main d-flex" style="height: 100%">
            <div style="width: 20%;height: 100%">
                <dv-border-box-3 style="padding: 30px">
                    <div style="height: 15%" class="text-center d-flex flex-column justify-space-around">
                        <p class="card-title">全国三级以上地震</p>
                        <div>
                            共
                            <span style="color: #006DFF;font-size: 25px;font-weight: bold">
                                {{ over3.cnt }}
                            </span>
                            个最大
                            <span style="color:  #969495;font-weight: bold;font-size: 18px;">
                                {{ over3.max }}
                            </span>
                            级
                        </div>
                        <dv-decoration-2 :dur=2 style="width:100%;height:5px;"/>
                    </div>
                    <div style="height: 15%;margin-top: 15px"
                         class="text-center d-flex flex-column justify-space-around">
                        <p class="card-title">全球六级以上地震</p>
                        <div>
                            共
                            <span style="color: #006DFF;font-size: 25px;font-weight: bold">
                                {{ over6.cnt }}
                            </span>
                            个最大
                            <span style="color:  #969495;font-weight: bold;font-size: 18px;">
                                {{ over6.max }}
                            </span>
                            级
                        </div>
                        <dv-decoration-2 :dur=2.3 style="width:100%;height:5px;"/>
                    </div>
                    <div style="height: 35%;margin-top: 15px">
                        <p class="card-title">全国地震次数排名</p>
                        <dv-scroll-ranking-board :config="provinceRank" style="width:100%;height: calc(100% - 60px)"/>
                        <dv-decoration-2 :dur=2.6 style="width:100%;height:5px;"/>
                    </div>
                    <div style="height: 35%;margin-top: 15px">
                        <p class="card-title">全国地震强度排名</p>
                        <dv-scroll-ranking-board :config="strengthRank" style="width:100%;height: calc(100% - 60px)"/>
                    </div>
                </dv-border-box-3>
            </div>
            <div style="width: 80%;margin-left: 5px;margin-bottom: 5px">
                <div style="height: 66.6%;" class="d-flex">
                    <div style="width: 66.6%;margin-right: 5px">
                        <dv-border-box-3 class="d-flex flex-column" style="padding: 30px;">
                            <p class="card-title">全球地震总数</p>
                            <div class="d-flex flex-column flex-grow-1" style="height: calc(100% - 60px)">
                                <div class="d-flex justify-center" style="margin-bottom: 10px">
                                    <div class="text-center" v-for="c in allCnt.toString()"
                                         style="color: #4DCFDB;font-size:25px;font-weight: bold;line-height: 60px;margin-right: 10px;height: 60px;width: 60px;background: rgba(4,49,128,.6);">
                                        {{ c }}
                                    </div>
                                </div>
                                <div class="d-flex" style="height: 100%">
                                    <div class="card-title d-flex flex-column justify-center" style="width: 25%">
                                        海外次数：{{ seaCnt }}
                                    </div>
                                    <div v-show="charts.show['overview']" id="overview" style="width: 50%"></div>
                                    <dv-loading v-show="!charts.show['overview']" style="width: 100%;">加载中...
                                    </dv-loading>
                                    <div class="card-title d-flex flex-column justify-center" style="width: 25%">
                                        国内次数：{{ chinaCnt }}
                                    </div>
                                </div>
                            </div>
                        </dv-border-box-3>
                    </div>
                    <div style="width: 33.3%;">
                        <dv-border-box-8 style="height: 50%;padding: 10px;margin-bottom: 5px;">
                            <dv-scroll-board v-show="offsetQuakeData.data!==[]" hoverPause :config="offsetQuakeData"/>
                            <dv-loading v-show="offsetQuakeData.data===[]">加载中...</dv-loading>
                        </dv-border-box-8>
                        <dv-border-box-4 style="height: 50%;padding: 30px" :reverse="true">
                            <div v-show="charts.show['chinaMap']" id="chinaMap" style="width: 100%;height: 100%"></div>
                            <dv-loading v-show="!charts.show['chinaMap']" style="width: 100%;">加载中...</dv-loading>
                        </dv-border-box-4>
                    </div>
                </div>
                <div style="height: 33.3%;">
                    <dv-border-box-4 style="padding: 30px 0 0 30px;">
                        <div class="d-flex justify-space-around fill-height">
                            <div v-show="charts.show['monthLine']" id="monthLine" style="width: 100%;"/>
                            <dv-loading v-show="!charts.show['monthLine']" style="width: 100%;">加载中...</dv-loading>
                            <div v-show="charts.show['quakeFrq']" id="quakeFrq" style="width: 100%;margin: 0 5px"/>
                            <dv-loading v-show="!charts.show['quakeFrq']" style="width: 100%;">加载中...</dv-loading>
                            <div v-show="charts.show['monthPillar']" id="monthPillar" style="width: 100%"/>
                            <dv-loading v-show="!charts.show['monthPillar']" style="width: 100%;">加载中...</dv-loading>
                        </div>

                    </dv-border-box-4>
                </div>
            </div>
        </div>
    </dv-full-screen-container>
</template>

<script>
    import * as bigScreen from '../network/details/bigScreen'
    import * as home from '../network/details/home'
    import * as query from '../network/details/query'
    import china from "../assets/js/china.js";

    export default {
        name: "Big_Screen",
        data: () => ({
            allCnt: "00000",
            seaCnt: 0,
            chinaCnt: 0,
            charts: {
                ids: ['overview', 'chinaMap', 'monthLine', 'quakeFrq', 'monthPillar'],
                obj: {
                    overview: null,
                    chinaMap: null,
                    monthLine: null,
                    quakeFrq: null,
                    monthPillar: null,
                },
                show: {
                    overview: true,
                    chinaMap: true,
                    monthLine: true,
                    quakeFrq: true,
                    monthPillar: true,
                }
            },
            offsetQuakeData: {
                header: ['震级', '地区', '时间'],
                data: [],
                index: true,
            },
            provinceRank: {
                data: [],
            },
            strengthRank: {
                data: [],
            },
            over3: {cnt: 0, max: 0},
            over6: {cnt: 0, max: 0},
        }),
        mounted() {
            //初始化自适应窗体大小
            window.onresize = () => {
                let keys = Object.keys(this.charts.obj)
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    if (this.charts.obj[key] !== null) {
                        this.charts.obj[key].resize();
                    }
                }
            }
            setTimeout(this.initEcharts)
        },
        created() {
            this.initData()
        },
        methods: {
            setMonthLineOption(data) {
                let labels = data.labels
                let series = []
                for (let i = 0; i < labels.length; i++) {
                    let label = labels[i]
                    series.push({
                        name: label,
                        type: 'line',
                        stack: 'Total',
                        // 需要获取的数据：每个月的地震次数
                        data: data['lv' + (i + 1)],
                        areaStyle: {}
                    })
                }
                let option = {
                    title: {
                        text: '地震统计',
                        textStyle: {
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        left: '70px',
                        data: labels,
                        textStyle: {
                            //图例文字的样式
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {},
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisLabel: {
                            textStyle: {
                                color: 'white'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        position: 'left',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: '{value} 次',
                            color: 'white'
                        }
                    },
                    series: series
                }
                this.charts.obj['monthLine'].setOption(option)
                this.charts.show['monthLine'] = true
            },
            setQuakeFrqOption(data) {
                let labels = data.labels
                let series = []
                for (let i = 0; i < labels.length; i++) {
                    let label = labels[i]
                    series.push({
                        name: label,
                        value: data['lv' + (i + 1)],
                    })
                }
                let option = {
                    title: {
                        text: '地震频次',
                        left: 'center',
                        textStyle: {
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '20px',
                        textStyle: {
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    series: [
                        {
                            name: '地震频次',
                            type: 'pie',
                            radius: ['20%', '50%'],
                            avoidLabelOverlap: false,

                            top: '20%',
                            emphasis: {
                                label: {
                                    formatter: '{b}地震，\n 共{c}次',
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            data: series
                        }
                    ]
                }
                this.charts.obj['quakeFrq'].setOption(option)
                this.charts.show['quakeFrq'] = true

            },
            setMonthPillarOption(data) {
                let labels = data.labels
                let series = []
                for (let i = 0; i < labels.length; i++) {
                    let label = labels[i]
                    series.push({
                        name: label,
                        type: 'bar',
                        stack: 'Total',
                        // 需要获取的数据：每个月的地震次数
                        data: data['lv' + (i + 1)],
                    })
                }
                let option = {
                    title: {
                        text: '地震统计',
                        textStyle: {
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        left: '70px',
                        data: labels,
                        textStyle: {
                            //图例文字的样式
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {},
                    xAxis: {
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisLabel: {
                            textStyle: {
                                color: 'white'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        position: 'left',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: '{value} 次',
                            color: 'white'
                        }
                    },
                    series: series
                }
                this.charts.obj['monthPillar'].setOption(option)
                this.charts.show['monthPillar'] = true
            },
            setOverViewOption(data) {
                this.seaCnt = data['sea']
                this.chinaCnt = data['in']
                data = [{value: data['sea'], name: "海外"}, {value: data['in'], name: "国内"}]
                let option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        bottom: '5%',
                        textStyle: {
                            //图例文字的样式
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    series: [
                        {
                            name: '概览',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '40',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: data
                        }
                    ]
                };
                this.charts.obj['overview'].setOption(option)
                this.charts.show['overview'] = true
            },
            initEcharts() {
                let ids = Object.values(this.charts.ids)
                for (let i = 0; i < ids.length; i++) {
                    let id = ids[i];
                    let ele = document.getElementById(id)
                    if (this.charts.obj[id] === null) {
                        this.charts.obj[id] = this.$echarts.init(ele)
                        this.charts.show[id] = false
                    }
                }
                window.addEventListener('resize', () => {
                    for (let i = 0; i < ids.length; i++) {
                        let id = ids[i];
                        let obj = this.charts.obj[id]
                        if (obj) {
                            obj.resize()
                        }
                    }
                });
                bigScreen.getBigScreen().then(res => {
                    let data = res.data;
                    let overView = data['overview']
                    this.allCnt = overView['all']
                    this.setOverViewOption(overView)
                })
            },
            initData() {
                home.getAllInfo().then(res => {
                    let data = res.data
                    this.over3 = data['over3'].data
                    this.over6 = data['earthOver6'].data
                    let provinceRank = data['provinceRank'].data
                    let strengthRank = data['rank'].data
                    let chinaMap = data['chinaMap'].data
                    let strengthMonth = data['strengthMonth'].data
                    let strengthYear = data['strengthYear'].data
                    this.setProvinceRank(provinceRank)
                    this.setStrengthRank(strengthRank)
                    this.setChinaMapOption(chinaMap)
                    this.setMonthLineOption(strengthMonth)
                    this.setMonthPillarOption(strengthMonth)
                    this.setQuakeFrqOption(strengthYear)
                })
                query.getAllByOffset({
                    hours: 24 * 3
                }, false).then(res => {
                    this.setScrollTable(res.data)
                })
            },
            setChinaMapOption(data) {
                let option = {
                    title: {
                        text: '地震分布',
                        x: 'center',
                        textStyle: {
                            //图例文字的样式
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '省份：{b}<br/>数值：{c}'
                    },

                    dataRange: {
                        min: 0,
                        max: Math.max.apply(null, data.map(item => item.value)),
                        x: 'left',
                        y: 'bottom',
                        text: ['高', '低'],           // 文本，默认为数值文本
                        calculable: true,
                        textStyle: {
                            //图例文字的样式
                            color: '#fff',
                            fontSize: 16
                        }

                    },
                    series: [
                        {
                            name: '数据',
                            type: 'map',
                            mapType: 'china',
                            roam: false,
                            itemStyle: {
                                emphasis: {label: {show: true}}
                            },
                            data: data
                        }
                    ]
                };
                this.charts.obj['chinaMap'].setOption(option)
                this.charts.show['chinaMap'] = true
            },
            setScrollTable(data) {
                let list = []
                for (let i = 0; i < data.length; i++) {
                    let magnitude = data[i]['magnitude']
                    let location = data[i]['location']
                    let time = data[i]['time']
                    list.push([magnitude, location, time])
                }
                this.offsetQuakeData = {
                    header: ['震级', '地区', '时间'],
                    columnWidth: [40, 50],
                    data: list,
                    index: true,
                }
            },
            setProvinceRank(data) {
                data = data.map(item => {
                    item.value = item.times
                    return item
                })
                this.provinceRank = {data: data}
            },
            setStrengthRank(data) {
                let list = []
                for (let i = 0; i < data.length; i++) {
                    let arr = data[i].split(" ")
                    let addr = arr[1]
                    let magnitude = parseFloat(arr[0].replace("级", ""))
                    list.push({
                        name: addr,
                        value: magnitude
                    })
                }
                this.strengthRank = {data: list}
            }
        }
    }
</script>

<style scoped>
    .d-container {
        color: white;
    }

    .bg {
        background: url('../assets/img/bg-screen.png');
        background-size: 100%
    }

    .header .page-title {
        position: absolute;
        font-size: 30px;
        font-weight: 700;
        left: 50%;
        top: 15px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .main {
        padding: 10px;
    }

    .card-title {
        text-align: center;
        font-size: 30px;
    }
</style>
