<template>
    <div class="d-flex flex-column" style="padding: 10px">
        <!--    第一行-->
        <v-card class="d-flex justify-space-between" style="padding:0 10px" width="100%" outlined>
            <div class="d-flex justify-start">
                <div style="line-height: 40px">
                    <p style="margin: 0;width: 120px">
                        最新消息 ({{ news[0] === '暂无消息' ? 0 : news.length }})：
                    </p>
                </div>
                <v-carousel cycle interval="4000" height="40px" :vertical=true :show-arrows="false" hide-delimiters>
                    <v-carousel-item style="line-height: 40px" v-for="s in news">
                        {{ s }}
                    </v-carousel-item>
                </v-carousel>
            </div>
            <v-btn style="margin: 6px" small outlined color="success" @click="gotoBigScreen">
                大屏演示
                <v-icon right>
                    mdi-lightbulb
                </v-icon>
            </v-btn>

        </v-card>
        <!--    第二行-->
        <div class="d-flex justify-space-around">
            <v-card style="margin: 10px 10px 10px 0;width: 25%">
                <v-sheet v-if="over3.cnt===0">
                    <v-skeleton-loader class="mx-auto" type="article"/>
                </v-sheet>
                <div v-else>
                    <p class="card2_11">全国三级以上地震</p>
                    <span class="card2_12">
                    共
                    <span style="color: #006DFF;font-size: 25px;font-weight: bold">
                        {{ over3.cnt }}
                    </span>
                    个最大
                    <span style="color:  #969495;font-weight: bold;font-size: 18px;">
                        {{ over3.max }}
                    </span>
                    级
                </span>
                </div>
            </v-card>
            <v-card style="margin: 10px ;width: 25%">
                <v-sheet v-if="over6.cnt===0">
                    <v-skeleton-loader class="mx-auto" type="article"/>
                </v-sheet>
                <div v-else>
                    <p class="card2_11">全球六级以上地震</p>
                    <span class="card2_12">共<span style="color: #006DFF;font-size: 25px;font-weight: bold">
                    {{ over6.cnt }}
                </span>
                    个最大
                    <span style="color:  #969495;font-weight: bold;font-size: 18px;">
                        {{ over6.max }}
                    </span>
                    级
                </span>
                </div>
            </v-card>
            <v-card style="margin: 10px ;width: 25%">
                <v-sheet v-if="province.length===0">
                    <v-skeleton-loader class="mx-auto" type="article"/>
                </v-sheet>
                <div v-else>
                    <p class="card2_13">全国地震省份排名</p>
                    <div style="margin: 0 20px 0 20px" v-for="i in province">
                    <span style="font-size: 10px ;color: #969495">
                        {{ i.name }} {{ i.times }}
                    </span>
                        <v-progress-linear :value="i.value"/>
                    </div>
                </div>


            </v-card>
            <v-card style="margin: 10px 0 10px 10px;width: 25%">
                <v-sheet v-if="rank.length===0">
                    <v-skeleton-loader class="mx-auto" type="article"/>
                </v-sheet>
                <div v-else>
                    <p class="card2_13">全国地震震级排名</p>
                    <div v-for="r in rank" class="card2_14_font">
                        {{ r }}
                    </div>
                </div>
            </v-card>
        </div>
        <div class="flex-grow-1">
            <!--    第三行-->
            <div style="height: calc(50% - 5px);" class="d-flex">
                <v-card style="width: 50%;padding: 10px" id="distribution"/>
                <v-card style="margin-left:10px;width: 50%;padding: 10px" id="statistic"/>
            </div>
            <!--    第四行-->
            <div style="height: calc(50% - 5px);margin-top: 10px" class="d-flex">
                <v-card style="width: 50%;padding: 10px" id="times"/>
                <v-card style="margin-left:10px;width: 50%;padding: 10px;" id="timesPeriod"/>
            </div>
        </div>


    </div>

</template>

<script>
    import china from "../assets/js/china.js";
    import * as home from "../network/details/home";
    import * as query from "../network/details/query";

    export default {
        name: 'Home',
        data: () => ({
            charts: {
                ids: ['distribution', 'statistic', 'times', 'timesPeriod'],
                obj: {
                    distribution: null,
                    statistic: null,
                    times: null,
                    timesPeriod: null
                }
            },
            over3: {cnt: 0, max: 0},
            over6: {cnt: 0, max: 0},
            province: [],
            rank: [],
            news: ['暂无消息']
        }),
        created() {
            this.initData()
        },
        mounted() {
            //初始化自适应窗体大小
            window.onresize = () => {
                let keys = Object.keys(this.charts.obj)
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    if (this.charts.obj[key] !== null) {
                        this.charts.obj[key].resize();
                    }
                }
            }
            setTimeout(this.echartsInit)
        },
        methods: {
            gotoBigScreen() {
                window.open('/bigScreen', '_blank')
            },
            setDistributionOption(data) {
                let option = {
                    title: {
                        text: '全国地震分布',
                        x: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '省份：{b}<br/>数值：{c}'
                    },

                    dataRange: {
                        min: 0,
                        max: Math.max.apply(null, data.map(item => item.value)),
                        x: 'left',
                        y: 'bottom',
                        text: ['高', '低'],           // 文本，默认为数值文本
                        calculable: true,
                    },
                    series: [
                        {
                            name: '数据',
                            type: 'map',
                            mapType: 'china',
                            roam: false,
                            itemStyle: {
                                emphasis: {label: {show: true}}
                            },
                            data: data
                        }
                    ]
                };
                this.charts.obj['distribution'].setOption(option)
                this.charts.obj['distribution'].hideLoading();
            },
            setStatisticOption(data) {
                let labels = data.labels
                let series = []
                for (let i = 0; i < labels.length; i++) {
                    let label = labels[i]
                    series.push({
                        name: label,
                        type: 'line',
                        stack: 'Total',
                        // 需要获取的数据：每个月的地震次数
                        data: data['lv' + (i + 1)],
                        areaStyle: {}
                    })
                }
                let option = {
                    title: {
                        text: '地震统计',
                        x: 'center',
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        center: 'center',
                        data: labels,
                        top: '30px'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {},
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                    },
                    yAxis: {
                        type: 'value',
                        position: 'left',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: '{value} 次'
                        }
                    },
                    series: series
                }
                this.charts.obj['statistic'].setOption(option)
                this.charts.obj['statistic'].hideLoading();
            },
            setTimesOption(data) {
                let labels = data.labels
                let series = []
                for (let i = 0; i < labels.length; i++) {
                    let label = labels[i]
                    series.push({
                        name: label,
                        value: data['lv' + (i + 1)],
                    })
                }

                let option = {
                    title: {
                        text: '地震频次',
                        // subtext: 'Fake Data',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            name: '地震频次',
                            type: 'pie',
                            radius: ['20%', '50%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },

                            emphasis: {
                                label: {
                                    formatter: '{b}地震，\n 共{c}次',
                                    show: true,
                                    fontSize: '20',
                                    fontWeight: 'bold'
                                }
                            },
                            data: series
                        }
                    ]
                }
                this.charts.obj['times'].setOption(option)
                this.charts.obj['times'].hideLoading();

            },
            setTimesPeriodOption(data) {
                let labels = data.labels
                let series = []
                for (let i = 0; i < labels.length; i++) {
                    let label = labels[i]
                    series.push({
                        name: label,
                        type: 'bar',
                        stack: 'Total',
                        // 需要获取的数据：每个月的地震次数
                        data: data['lv' + (i + 1)],
                    })
                }
                let option = {
                    title: {
                        text: '地震时间统计',
                        x: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        icon: 'circle',
                        data: labels,
                        left: "center",
                        top: '30px'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            position: 'left',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                            },
                            axisLabel: {
                                formatter: '{value} 次'
                            }
                        }

                    ],
                    series: series
                }
                this.charts.obj['timesPeriod'].setOption(option)
                this.charts.obj['timesPeriod'].hideLoading();

            },
            initData() {
                home.getAllInfo().then(res => {
                    let data = res.data
                    //全国三级以上地震信息
                    this.over3 = data['over3'].data
                    //全球六级以上地震信息
                    this.over6 = data['earthOver6'].data
                    //省级地震排行
                    this.rank = res.data.rank.data
                    this.province = res.data.provinceRank.data
                    this.disposeData()
                    let chinaMap = data['chinaMap'].data
                    let strengthMonth = data['strengthMonth'].data
                    let strengthYear = data['strengthYear'].data
                    this.setDistributionOption(chinaMap);
                    this.setStatisticOption(strengthMonth)
                    this.setTimesPeriodOption(strengthMonth)
                    this.setTimesOption(strengthYear)
                    console.log(res)
                })
                query.getAllByOffset({
                    hours: 24 * 3
                }, false).then(res => {
                    this.news = res.data.map(item => {
                        return item.time + " 于 " + item.location + "发生 " + item.magnitude + " 级地震"
                    })
                })
            },
            //初始化图表
            echartsInit() {
                let ids = Object.values(this.charts.ids)
                for (let i = 0; i < ids.length; i++) {
                    let id = ids[i];
                    let ele = document.getElementById(id)
                    if (this.charts.obj[id] === null) {
                        this.charts.obj[id] = this.$echarts.init(ele)
                        this.charts.obj[id].showLoading();
                    }
                }
                window.addEventListener('resize', () => {
                    for (let i = 0; i < ids.length; i++) {
                        let id = ids[i];
                        let obj = this.charts.obj[id]
                        if (obj) {
                            obj.resize()
                        }
                    }
                });
            },
            //地震身份排名数据处理
            disposeData() {
                let all = 0
                for (let i in this.province) {
                    all += this.province[i].times
                }
                for (let i in this.province) {
                    let v = (this.province[i].times / all) * 100
                    this.province[i].value = v
                }
            }
        }

    }
</script>

<style scoped lang="less">

    .card2_11 {
        //border: red 2px solid;
        color: #464646;
        font-weight: bold;
        font-size: 19px;
        margin: 20px 20px 10px 20px
    }

    .card2_12 {
        font-size: 15px;
        color: #969495;
        margin: 0 50px 50px 20px;

    }

    .card2_13 {
        color: #464646;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 20px 0 20px
    }

    .card2_14_font {
        margin: 4px 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 10px;
        color: #1976D2;
        font-weight: bold
    }
</style>
